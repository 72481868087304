import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import MetaTitle from "components/Shared/MetaTitle";
import { doLoginUserCleanup, loginUser, saveReturnUrl } from "store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-simple.svg";
import { route, routes } from "helpers/routeHelper";
import { AccessDeniedException, AuthException, AuthLockedException, LOGIN_INVALID_CREDENTIALS, LOGIN_USER_NOT_ACTIVATED, LOGIN_USER_NOT_FOUND, MAX_FAILED_AUTH_ATTEMPTS_REACHED, ValidationException } from "helpers/errorHelper";
import PasswordInput from "components/Shared/PasswordInput";
import CountdownTimer from "components/Shared/CountdownTimer";

const Login = () => {

  const location = useLocation();
  const navigate = useNavigate();

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // read return url from query string
  let retUrl = new URLSearchParams(location.search).get('ret');

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: '',
      passwd: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please enter your email').email('Please enter a valid email address'),
      passwd: Yup.string().required('Please enter your password'),
    }),
    onSubmit: values => dispatch(loginUser(values)),
  });

  /********** STATE **********/

  const { isLoginInProgress, loggedIn, loginError, returnUrl, hasTfa, hasPasswdChange, passwdChangeToken } = useSelector(state => state.Auth.Login);

  const [error, setError] = useState(null);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    const excludedRoutes = [
      routes.login,
      routes.logout,
      routes.register,
      routes.lost_password,
      routes.reset_password,
      routes.two_factor_auth,
      routes.activate_account,
      routes.verify_email,
    ];
    if (!!retUrl && excludedRoutes.every(excludedRoute => {
      const url = new URL(retUrl);
      return matchPath(excludedRoute, url.pathname) === null;
    })) {
      // save the return url in state
      dispatch(saveReturnUrl(retUrl));
    }
  }, [dispatch, retUrl]);

  useEffect(() => {
    return () => {
      dispatch(doLoginUserCleanup());
    }
  }, []);

  // runs whenever the 'loggedIn' flag changes
  // which happens after a login attempt
  useEffect(() => {
    if (loggedIn === true) {
      if (hasTfa) {
        navigate(route(routes.two_factor_auth));
      } else if (hasPasswdChange) {
        navigate(route(routes.reset_password, passwdChangeToken));
      } else {
        const redirectTo = returnUrl || route(routes.home);
        window.location.replace(redirectTo);
      }
    } else if (loggedIn === false) {
      formik.setSubmitting(false);
      let errMessage = 'Unable to login';
      // see if the login failed due to validation
      if (loginError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(loginError.fields)) {
          formik.setFieldError(name, message);
        }
        // see if the login failed due to the account being locked
      } else if (loginError instanceof AuthLockedException) {
        switch (loginError.code) {
          case MAX_FAILED_AUTH_ATTEMPTS_REACHED:
            errMessage = <div>You have reached the maximum number of failed login attempts. The account is locked. <CountdownTimer targetDate={loginError.lockedUntil * 1000} /></div>;
            break;
        }
        // see if the login failed due to credentials
      } else if (loginError instanceof AuthException) {
        switch (loginError.code) {
          case LOGIN_USER_NOT_FOUND:
            errMessage = 'User not found';
            break;
          case LOGIN_INVALID_CREDENTIALS:
            errMessage = 'Invalid credentials';
            break;
        }
      } else if (loginError instanceof AccessDeniedException) {
        switch (loginError.code) {
          case LOGIN_USER_NOT_ACTIVATED:
            errMessage = 'Your account is not active yet! An email has been sent to you with the activation link. Follow the steps to set up your password and log in';
            break;
        }
      }
      setError(errMessage);
    }
  }, [loggedIn]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    formik.setFieldError(name, null);
  };

  return <React.Fragment>
    <MetaTitle>Login</MetaTitle>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col xs={7}>
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Welcome Back!</h5>
                      <p>Sign in to continue to Mavsign</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to={route(routes.home)} className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} height="44" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <Form
                    noValidate
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit} >
                    {error && <Alert color="danger">{error}</Alert>}

                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        onChange={formik.handleChange}
                        onFocus={onFieldFocused}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        invalid={!!formik.errors.email} />
                      {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <PasswordInput
                        name="passwd"
                        placeholder="Enter Password"
                        onChange={formik.handleChange}
                        onFocus={onFieldFocused}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwd}
                        errors={formik.errors.passwd} />
                    </div>

                    <div className="mt-3 d-grid">
                      <Button type="submit" color="primary" className="btn-block" disabled={isLoginInProgress}>
                        {isLoginInProgress && <i className="mdi mdi-spin mdi-loading me-2" />}
                        Log In
                      </Button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to={route(routes.lost_password)} className="text-muted">
                        <i className="mdi mdi-lock me-1" />Forgot your password?
                      </Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
            {/*<div className="mt-5 text-center">
              <p>Don&apos;t have an account ? <a href="https://mavsign.com" target="_blank" rel="noreferrer" className="fw-medium text-primary">Sign up now</a></p>
              <p>© {new Date().getFullYear()} Maverick Signings</p>
            </div>*/}
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
};

export default Login;