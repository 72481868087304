import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import MetaTitle from "components/Shared/MetaTitle";
import { doVerifCodeCleanup, resendVerifCode, validateVerifCode } from "store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-simple.svg";
import { route, routes } from "helpers/routeHelper";
import { AccessDeniedException, AuthLockedException, MAX_FAILED_AUTH_ATTEMPTS_REACHED, TFA_TOKEN_EXPIRED_CODE, TFA_TOKEN_INVALID_CODE, TFA_TOKEN_RESEND_ABUSE, ValidationException } from "helpers/errorHelper";
import classnames from "classnames";
import { showSuccess } from "helpers/utilHelper";
import config from "config";
import CountdownTimer from "components/Shared/CountdownTimer";

const TwoFactorAuth = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      verifCode: '',
    },
    validationSchema: Yup.object({
      verifCode: Yup.string().required('Field is required'),
    }),
    onSubmit: values => dispatch(validateVerifCode(values)),
  });

  /********** STATE **********/

  const { loggedIn, isTfaLoginInProgress, tfaLoggedIn, tfaLoginError, returnUrl,
    codeResent, codeResendError, isCodeResendInProgress, hasPasswdChange, passwdChangeToken } = useSelector(state => state.Auth.Login);

  const [error, setError] = useState(null);

  const [resendCodeTimer, setResetCodeTimer] = useState(null);

  /********** EFFECTS **********/

  useEffect(() => {
    if (!loggedIn) {
      navigate(route(routes.login));
    }
    return () => {
      dispatch(doVerifCodeCleanup());
    }
  }, []);

  // runs whenever the resendCodeTimer flag changes
  useEffect(() => {
    if (resendCodeTimer == null) {
      setResetCodeTimer(config.SECURITY_CODE_RESEND_DELAY);
    }
    let timer;
    if (resendCodeTimer > 0) {
      timer = setTimeout(() => setResetCodeTimer(resendCodeTimer - 1), 1000);
    }
    if (resendCodeTimer == 0) {
      setResetCodeTimer(-1);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [resendCodeTimer]);

  // runs whenever the 'loggedIn' flag changes
  // which happens after a login attempt
  useEffect(() => {
    if (tfaLoggedIn === true) {
      if (hasPasswdChange) {
        navigate(route(routes.reset_password, passwdChangeToken));
      } else {
        const redirectTo = returnUrl || route(routes.home);
        window.location.replace(redirectTo);
      }
    } else if (tfaLoggedIn === false) {
      formik.setSubmitting(false);
      let errMessage = 'Unable to validate code';
      // see if the login failed due to validation
      if (tfaLoginError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(tfaLoginError.fields)) {
          formik.setFieldError(name, message);
        }
        // see if the login failed due to the account being locked
      } else if (tfaLoginError instanceof AuthLockedException) {
        switch (tfaLoginError.code) {
          case MAX_FAILED_AUTH_ATTEMPTS_REACHED:
            errMessage = <div>You have reached the maximum number of failed login attempts. The account is locked. <CountdownTimer targetDate={tfaLoginError.lockedUntil * 1000} /></div>;
            break;
        }
        // see if the login failed due to a known error
      } else if (tfaLoginError instanceof AccessDeniedException) {
        switch (tfaLoginError.code) {
          case TFA_TOKEN_INVALID_CODE:
            errMessage = 'Invalid verification code';
            break;
          case TFA_TOKEN_EXPIRED_CODE:
            errMessage = 'Verification code has expired';
            break;
        }
      }
      setError(errMessage);
    }
  }, [tfaLoggedIn]);

  // runs whenever the 'codeResent' flag changes
  // which happens after a resen-code attempt
  useEffect(() => {
    if (codeResent === true) {
      showSuccess('A new verification code has been sent');
      setResetCodeTimer(config.SECURITY_CODE_RESEND_DELAY);
    } else if (codeResent === false) {
      let errMessage = 'Unable to send a new verification code';
      // see if the login failed due to a known error
      if (codeResendError instanceof AccessDeniedException) {
        switch (codeResendError.code) {
          case TFA_TOKEN_RESEND_ABUSE:
            errMessage = `Please wait ${config.SECURITY_CODE_RESEND_DELAY} seconds before requesting a new code`;
            break;
        }
      }
      setError(errMessage);
    }
  }, [codeResent]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    formik.setFieldError(name, null);
  };

  const doResendVerifCode = () => {
    setError(null);
    dispatch(resendVerifCode());
  }

  const isTimerRunning = () => !!resendCodeTimer && resendCodeTimer > 0;

  const canResendCode = () => !isCodeResendInProgress && !isTimerRunning();

  // key down event handler that disables the default behavior of number inputs of increasing/decreasing the value when arrow keys are pressed
  const onFieldKeyDown = e => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  }

  return <React.Fragment>
    <MetaTitle>Verification Code</MetaTitle>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col xs={7}>
                    <div className="text-primary p-4">
                      <h5 className="text-primary">One More Step!</h5>
                      <p>Enter the verification code to continue to Mavsign</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to={route(routes.home)} className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} height="44" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <Form
                    noValidate
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit} >
                    {error && <Alert color="danger">{error}</Alert>}

                    <div className="mb-3">
                      <Label className="form-label">Verification Code</Label>
                      <div className="mb-2">Check your email and SMS for the verification code.</div>
                      <Input
                        type="number"
                        name="verifCode"
                        className="form-control verification-code"
                        placeholder="Enter 4-digit code"
                        onChange={e => formik.setFieldValue('verifCode', e.target.value.slice(0, 4))}
                        onFocus={onFieldFocused}
                        onBlur={formik.handleBlur}
                        onKeyDown={onFieldKeyDown}
                        value={formik.values.verifCode}
                        invalid={!!formik.errors.verifCode} />
                      {!!formik.errors.verifCode && <FormFeedback type="invalid">{formik.errors.verifCode}</FormFeedback>}
                    </div>

                    <div className="mt-3 d-grid">
                      <Button type="submit" color="primary" className="btn-block" disabled={isTfaLoginInProgress}>
                        {isTfaLoginInProgress && <i className="mdi mdi-spin mdi-loading me-2" />}
                        Submit
                      </Button>
                    </div>

                    <div className="text-center">
                      <Button type="button" color="link" className="text-muted" onClick={doResendVerifCode} disabled={!canResendCode()}>
                        <i className={classnames('mdi mdi-refresh me-1', { 'mdi-spin': isCodeResendInProgress })} />
                        Resend code {isTimerRunning() && `in ${resendCodeTimer} seconds`}
                      </Button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
            {/*
              <div className="mt-5 text-center">
                <p>Don&apos;t have an account ? <a href="https://mavsign.com" target="_blank" rel="noreferrer" className="fw-medium text-primary">Sign up now</a></p>
                <p>© {new Date().getFullYear()} Maverick Signings</p>
              </div>
            */}
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
};

export default TwoFactorAuth;