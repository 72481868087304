import React from "react";
import { useCountdown } from "hooks/useCountdown";
import PropTypes from "prop-types";

const addLeadingZero = value => value < 10 ? `0${value}` : value;

const CountdownTimer = ({ targetDate }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);

  if (hours + minutes + seconds <= 0) return <div>Try again now.</div>

  return (
    <div>
      Try again in
      <span className="ms-1">{addLeadingZero(minutes)}</span>
      <span>:</span>
      <span>{addLeadingZero(seconds)}</span>
    </div>
  );
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.number
}

export default CountdownTimer;

